<template>
  <div class="container">
    <div class="kbai"></div>
    <div class="ban">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item, index) in banners" :key="index">
          <img v-lazy="item" src="" alt="" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div
      class="cate-list"
      v-for="(cate, index) in cateList"
      :key="'cate-list-' + index"
    >
      <div class="title">{{ cate.title }}</div>
      <div class="item_list">
        <div
          class="item"
          v-for="(item, idx) in cate.child"
          :key="'item_' + idx"
          @click="jumpUrl(item.link_url)"
        >
        <div class="" >
          <img class="img" :alt="item.name" v-lazy="item.cover" />
          <span class="span">{{ item.name }}</span>
          <p class="user" v-if="item.username">{{ item.username }}</p>
          <p v-else-if="idx == 5"></p>
          <p v-else>{{ stat[idx].count }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="bottom">
      <div class="icon" @click="jumpUrl('/my')">
        <img :src="myself" alt="" />
        <span>我的</span>
      </div>
      <div class="paidan" v-if="issuper" @click="jumpUrl('/zhipai')">
        <div class="content">
          <img :src="zhipai" alt="" />
          <span>在线派单</span>
        </div>
      </div>
    </div> -->

    <!-- 测试 -->
  </div>
</template>
<script>
import { getHomePage, getCount } from "@/api/index";
import { getDeviceList } from "@/api/device";
import { getGaojingList } from "@/api/gaojing";
import { getGongdanList } from "@/api/gongdan";
import HeCUnImg from "@/assets/images/hecun.png";
import Banner1Img from "@/assets/images/banner1.jpg";
import Banner2Img from "@/assets/images/banner2.jpg";
import Banner3Img from "@/assets/images/banner3.jpg";
import DefaultImg from "@/assets/images/default.jpg";
import ZhiPai from "@/assets/images/zhipai.png";
import MySelf from "@/assets/images/my.png";
import JianKong from "@/assets/images/jksb.png";
import DaiBan from "@/assets/images/dbsx.png";
import GaoJing from "@/assets/images/gjsj.png";
import ShangBao from "@/assets/images/shangbao.png";
import GeRen from "@/assets/images/grzx.png";
import PaiDan from "@/assets/images/zxpd.png";
import { formatParams } from "@/utils/params";
import { getStorage } from "@/utils/auth";
import Vue from "vue";

import { Form, Field, Button } from "vant";
import {
  Icon,
  Cell,
  CellGroup,
  Empty,
  Tag,
  Toast,
  Swipe,
  SwipeItem,
  Badge,
} from "vant";
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Badge);
Vue.use(Icon);
export default {
  name: "HomeIndex",
  components: {
    [Toast.name]: Toast,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Empty.name]: Empty,
    [Tag.name]: Tag,
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button,
  },
  data() {
    return {
      account: "",
      password: "",
      hecun: HeCUnImg,
      banners: [Banner1Img, Banner2Img, Banner3Img],
      defaultimg: DefaultImg,
      myself: MySelf,
      zhipai: ZhiPai,
      gongdancount: 0,
      params: {
        parameters: [
          {
            key: "Q^STATUS_^S",
            value: "",
          },
        ],
        requestPage: {
          pageNo: 1,
          limit: 999,
        },
      },
      stat: [
        { name: "gongdan", count: 0 },
        { name: "gaojing", count: 0 },
        { name: "shangbao", count: 0 },
        { name: "device", count: 0 },
        { name: "zhipai", count: 0 },
        { name: "shenhe", count: 0 },
        { name: "shenhe", count: 0 },
      ],
      issuper: false,
      list: [],
      finallist: [],
     
       cateList: [
        {
          title: "快捷工具",
          child: [
            {
              name: "待办事项",
              cover: DaiBan,
              link_url: "/gongdan/index?status=1",
            },
            {
              name: "告警事件",
              cover: GaoJing,
              link_url: "/gaojing/index",
            },
            {
              name: "工单上报",
              cover: ShangBao,
              link_url: "/gongdan/index",
            },
            {
              name: "设备详情",
              cover: JianKong,
              link_url: "/device/index",
            },
            {
              name: "个人中心",
              cover: GeRen,
              link_url: "/my",
              username: localStorage.getItem("haoqee_name"),
            },
            {
              name: "在线派单",
              cover: PaiDan,
              link_url: "/zhipai",
            },
            {
              name: "审核列表",
              cover: PaiDan,
              link_url: "/shenhe/index",
            },
          ],
        },
      ],
      // supercateList: [
      //   {
      //     title: "江山治水",
      //     child: [
      //       {
      //         name: "工单",
      //         cover: GongDan,
      //         link_url: "/gongdan/index",
      //         bgc: "#E9F3FF",
      //       },
      //       {
      //         name: "告警",
      //         cover: GaoJing,
      //         link_url: "/gaojing/index",
      //         bgc: "pink",
      //       },
      //       {
      //         name: "监控设备",
      //         cover: JianKong,
      //         link_url: "/device/index",
      //         bgc: "#D4F7EF",
      //       },
      //       {
      //         name: "我的",
      //         cover: WoDe,
      //         link_url: "/my",
      //         bgc: "#FDEBDC",
      //       },
      //       {
      //         name: "工单指派",
      //         cover: ZhiPai,
      //         link_url: "/zhipai",
      //         bgc: "#FED5B7",
      //       },
      //     ],
      //   },
      // ],
    };
  },
  computed: {},
  created() {
    this.parseTime = this.utils.parseTime;
    this.issuper = getStorage("super");
    this.issuper = JSON.parse(this.issuper);
    // this.issuper=false
    if(!this.issuper){

      this.cateList[0].child[2].name='已办事项'
       this.cateList[0].child.pop()
       this.cateList[0].child.pop()
    }
    
    //this.getHomePage();
    this.getDeviceList();
    this.getGongdanList();
    // this.getShangbaoList();
    this.getGaojingList();
    this.getShenHeList()
  },
  mounted() {
    this.getcount();
    this.jumpUrl = this.utils.jumpUrl;
  },
  updated() {},
  methods: {
    handleSubmit() {},
    jumpUrl(_url, num) {
      this.$router.push({
        path: _url,
        query: {
          status: num,
        },
      });
    },
    async getcount() {
      let res = await getCount();
      this.stat[0].count = res.data;
      console.log(this.gongdancount);
    },
    getDeviceList() {
      const params = this.params;
      getDeviceList(params)
        .then((response) => {
          this.stat.device = response.data.pageResult.totalCount;
          let arr = response.data.dataResult;
          this.stat[3].count = 0;
          arr.forEach((element) => {
            if (element.netStatus == "在线") {
              this.stat[3].count++;
            }
          });
          console.log(response);
        })
        .catch((err) => {
          Toast.fail(err);
        });
    },
    async getGaojingList() {
      const params = this.params;
      await getGaojingList(params)
        .then((response) => {
         
          let arr = response.data.dataResult;
          this.stat[1].count = arr[0].totalNum;
          // arr.forEach((element) => {
          //   if (element.status == "处理中") {
          //     this.stat[1].count++;
          //   }
          // });
        })
        .catch((err) => {
          Toast.fail(err);
        });
    },
    
    async getShenHeList() {
      const params = {
        parameters: [
          {
            key: "Q^STATUS_^S",
            value: 3,
          },
        ],
        requestPage: {
          pageNo: 1,
          limit: 10000,
        },
      }
      await getGongdanList(params)
        .then((response) => {
           this.stat[6].count = response.data.pageResult.totalCount;
          
        })
        .catch((err) => {
          Toast.fail(err);
        });
    },
    async getGongdanList() {
      const params = formatParams(
        { "Q^ORDER_NAME_^SL": "", "Q^STATUS_^S": "" },
        { page: 1 }
      );
      await getGongdanList(params)
        .then((response) => {
           this.stat[2].count = response.data.pageResult.totalCount;
          this.list = response.data.dataResult;
          console.log(555);
          console.log(this.list);
        })
        .catch((err) => {
          Toast.fail(err);
        });
    },

     getShangbaoList() {
      const params = formatParams(
        { "Q^ORDER_NAME_^SL": "", "Q^STATUS_^S": "" },
        { page: 1 }
      );
     getGongdanList(params)
        .then((response) => {
          this.stat[2].count = response.data.pageResult.totalCount;
          console.log( '上报:' ,response.data);
        })
        .catch((err) => {
          Toast.fail(err);
        });
    },

    getHomePage() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      getHomePage({})
        .then((res) => {
          this.modules = res.data.modules;
          Toast.clear();
        })
        .catch((err) => {
          console.log(err);
          Toast.clear();
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.big {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  .div {
    height: 10rem;
    width: 90vw;
    background-color: #ffffff;
    .kong {
      width: 10vw;
      height: 1.2rem;
    }
    .hecun {
      width: 35%;
      position: absolute;
      z-index: 999;
      top: -15%;
      left: 32.5%;
    }
    h6 {
      margin-left: 0.5rem;
      margin-bottom: 0.2rem;
      font-size: 0.5rem;
      font-weight: 550 !important;
    }
    .van-form {
      width: 100%;
    }
  }
}
.container {
  background-image: url("../../assets/images/logbgimg.jpg");
  background-repeat: no-repeat;
  height: 100vh;
  background-position-y: -2.3rem;
  background-size: 123%;
  .kbai {
    height: 0.3rem;
  }
}
.van-badge {
  position: absolute;
  right: -0.3rem;
  top: -0.3rem;
  width: 0.9rem;
  height: 0.9rem;
  font-size: 0.55rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container {
  padding-bottom: 20px;
  .ban {
    width: 95vw !important;
    height: 25vh !important;
    margin: 0 auto;
    border-radius: 15px !important;
      transform: translate3d(0,0,0);
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    // padding-top: 0.3rem;
    img {
      width: 100%;
      height: 25vh;
      // border-radius: 15px;
    }
  }
}
.cate-list {
  // 普通版的title样式
  margin: 10px 0;
  .title {
    // margin-bottom: 20px;
    margin: 0.4rem 0;
    margin-left: 0.32rem;
    margin-top: 0.5rem;
    font-size: 0.5rem;
    font-weight: 750;
    // color: #ffffff;
    color: #808080;
  }

  //普通版本itemlist的样式
  .item_list {
    padding: 0 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 0.55rem;
    .item {
      display: flex;
      border-radius: 10%;
      flex-direction: column;
      align-items: center;
      // padding: 0 0.5rem;
      width: 150px;
      position: relative;
      .img {
        width: 5rem;
        height: 2.8rem;
        // margin: 0 0.1rem;
      }

      .span {
        font-size: 0.4rem;
        position: absolute;
        color: #ffffff !important;
        top: 23%;
        left: 0.3rem;
        color: #000;
      }
      p {
        font-size: 0.6rem;
        position: absolute;
        color: #ffffff !important;
        top: 50%;
        left: 0.3rem;
        color: #000;
      }
      .user {
        font-size: 0.45rem;
        position: absolute;
        color: #ffffff !important;
        top: 50%;
        left: 0.3rem;
        color: #000;
      }
    }
  }
}

.bottom {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 0;
  right: 0;
  bottom: 0.5rem;
  height: 1.3rem;
  margin: 0.2rem 0.5rem;
  .paidan {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0;
    width: 4rem;

    .content {
      width: 4rem;
      margin: 0;
      font-size: 0.5rem;
      position: relative;
      img {
        width: 100%;
        height: 1.5rem;
      }
      span {
        position: absolute;
        left: 35%;
        top: 23%;
        font-size: 0.45rem;
      }
    }
  }
  .icon {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    img {
      width: 0.8rem;
      height: 0.8rem;
    }
    span {
      font-size: 0.4rem;
    }
  }
}
</style>
