import request from '@/utils/request'

// 获取首页
export function getHomePage(data) {
  return request({
    method: 'post',
    path: 'index/index',
    data
  })
}
//获取工单角标
export function getCount(data) {
  return request({
    method: 'post',
    path: 'webapi/waterOrder/orderCount',
    data
  })
}